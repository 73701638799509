import React, { Component } from "react";
import CustomOAuthButton from '../CustomOAuthButton';
import { Auth, API } from 'aws-amplify';
import "../css/Home.css";


export default class Links extends Component {
    constructor(props) {
        super(props);
    }

    renderLinks() {
      return(
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>Report Links</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div class="list-group">
                <a href="https://www.arizona.edu" class="list-group-item">UArizona Home Page</a>
                <a href="https://covid19.arizona.edu/" class="list-group-item">COVID-19 Info Page</a>
                <a href="https://it.arizona.edu" class="list-group-item">UA IT Home page</a>
              </div>
            </div>
          </div>
        </div>
      )
    }

    renderLander() {
        return (
            <div className="lander">
                <h2>COVID-19 Vial Scanner</h2>
                <p>Log-in with your NetID.</p>
                <CustomOAuthButton variant="primary" size="lg">LOGIN</CustomOAuthButton>
            </div>
        );
    }

    renderUnauthorized() {
        return (
            <div className="lander">
                <h2>COVID-19 Vial Scanner</h2>
                <p>Log-in with your NetID</p>
                <div className="alert alert-danger" role="alert">You do not have the appropriate permissions to use this application.</div>
            </div>
        );
    }

    render() {
        return (
            <div className="Home">
                {(this.props.authn === 'signedIn' && this.props.authz) && this.renderLinks()}
                {(this.props.authn === 'signedIn' && !this.props.authz) && this.renderUnauthorized()}
                {(this.props.authn !== 'signedIn') && this.renderLander()}
            </div>
        );
    }
}
